import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step context`}</strong>{` -- manage certificate authority contexts`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step context [global-flags] <subcommand> [arguments] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step context`}</strong>{` command group provides facilities to manage certificate
authority contexts.`}</p>
    <h2>{`Examples`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat $(step path --base)/contexts.json
{
    "alpha-one": {
        "authority": "alpha-one.ca.smallstep.com",
        "profile": "alpha-one"
    },
    "alpha-two": {
        "authority": "alpha-two.ca.smallstep.com",
        "profile": "alpha-two"
    },
    "beta": {
        "authority": "beta.ca.smallstep.com",
        "profile": "beta"
    }
}
`}</code></pre>
    <p>{`Select the default certificate authority context:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step context select alpha-one
`}</code></pre>
    <p>{`List the available certificate authority contexts:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step context list
▶ alpha-one
alpha-two
beta
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "current/"
              }}>{`current`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`current returns the name of the current context`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "list/"
              }}>{`list`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`list available certificate authority contexts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "remove/"
              }}>{`remove`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`remove a context and all associated configuration`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "select/"
              }}>{`select`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`select the default certificate authority context`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      